<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0 pt-5">
			<h3 class="card-title align-items-start flex-column">
				<span class="card-label font-weight-bolder text-dark">{{ $t('MENU.Survey.designs') }}</span>
			</h3>
			<div class="card-toolbar">
				<router-link :to="{ name: 'survey-design', params: { id: 'create' } }" v-slot="{ href, navigate, isActive, isExactActive }">
					<a :href="href" @click="navigate" class="btn btn-icon btn-light btn-sm mx-1" :class="!hasPermission('action.theme.add') ? 'disabled' : ''">
						<i class="fas fa-plus text-primary"></i>
					</a>
				</router-link>
			</div>
		</div>
		<div class="card-body py-3">
			<ContentLoading size="1.5" v-if="pageProcesses.isLoading"></ContentLoading>
			<div class="col-12 prinor-table" v-if="!pageProcesses.isLoading">
				<b-table ref="themesTable" :data="themes" :paginated="true" :per-page="15" default-sort="created_at" :default-sort-direction="'desc'" :sort-icon="'caret-up'">
					<b-table-column field="name" :label="$t('GENERAL.Forms.name')" v-slot="props" :sortable="true" :searchable="true" cell-class="text-valign">
						<template>
							<span class="truncate">{{ props.row.name }}</span>
						</template>
					</b-table-column>
					<b-table-column field="created_at" :label="$t('VIEWS.Survey.All.createdAt')" v-slot="props" :sortable="true" :searchable="false" cell-class="text-valign">
						<template>
							<span class="truncate">{{ new Date(props.row.created_at).toLocaleString() }}</span>
						</template>
					</b-table-column>
					<b-table-column field="updated_at" :label="$t('VIEWS.Survey.Theme.updatedAt')" v-slot="props" :sortable="true" :searchable="false" cell-class="text-valign">
						<template>
							<span class="truncate">{{ new Date(props.row.updated_at).toLocaleString() }}</span>
						</template>
					</b-table-column>
					<b-table-column field="id" :label="$t('GENERAL.General.actions')" v-slot="props" cell-class="text-right" header-class="text-right" width="15rem">
						<template>
							<a href="" @click.prevent="showCopy(props.row.id, props.row.name)" class="btn btn-icon btn-light btn-sm mx-1" :class="!hasPermission('action.theme.add') ? 'disabled' : ''">
								<i class="far fa-copy text-primary"></i>
							</a>
							<router-link :to="{ name: 'survey-design', params: { id: props.row.id } }" v-slot="{ href, navigate, isActive, isExactActive }">
								<a :href="href" @click="navigate" class="btn btn-icon btn-light btn-sm mx-1" :class="!hasPermission('action.theme.edit') || props.row.default === true || props.row.read_only === true ? 'disabled' : ''">
									<i class="fas fa-magic text-primary"></i>
								</a>
							</router-link>
							<a href="" @click.prevent="onDelete(props.row.id, props.row.name)" class="btn btn-icon btn-light btn-sm mx-1" :class="!hasPermission('action.theme.delete') || props.row.default === true || props.row.read_only === true ? 'disabled' : ''">
								<i class="far fa-trash-alt text-danger"></i>
							</a>
						</template>
					</b-table-column>
				</b-table>
			</div>
		</div>
	</div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { PRINOR_TOASTS } from '@/core/services/toast.service';
import ContentLoading from '@/view/component/misc/ContentLoading';
import Swal from 'sweetalert2';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { PRINOR_PERMISSION } from '@/core/services/permissions.service';
import {SET_ACTION} from "@/core/services/store/actions.module";
import {PRINOR_PROCESSES} from "@/core/services/actions.service";
import {mapGetters} from "vuex";

export default {
	name: 'AllDesigns',
	components: { ContentLoading },
	data() {
		return {
			themes: [],
		};
	},
	mounted() {
		this.$store.dispatch(SET_ACTION, []);
		this.$store.dispatch(SET_BREADCRUMB, [
			{
				title: this.$t('MENU.Survey.surveys'),
				route: '/survey',
			},
			{ title: this.$t('MENU.Survey.designs') },
		]);
		PRINOR_PROCESSES.isLoading();

		this.onGet();
	},
	computed: {
		...mapGetters(['pageProcesses']),
	},
	methods: {
		hasPermission(name) {
			return PRINOR_PERMISSION.action(name);
		},
		onGet: function() {
			this.get().then(data => {
				this.themes = data.data;
			});
		},
		showCopy: function(id, name) {
			Swal.fire({
				title: this.$t('VIEWS.Survey.Theme.copy'),
				text: this.$t('VIEWS.Survey.Theme.copyText', { name: name }),
				icon: 'question',
				input: 'text',
				inputLabel: this.$t('VIEWS.Survey.Theme.copyInput'),
				inputValue: '',
				confirmButtonText: this.$t('GENERAL.Buttons.copy'),
				showCancelButton: true,
				cancelButtonText: this.$t('GENERAL.Buttons.cancel'),
				customClass: {
					confirmButton: 'btn btn-success',
					cancelButton: 'btn btn-secondary',
				},
				heightAuto: false,
				allowOutsideClick: true,
				allowEscapeKey: true,
				allowEnterKey: false,
			}).then(result => {
				if (result.isConfirmed) {
					this.duplicate(id, result.value).then(data => {
						if (data.success === true) {
							this.onGet();
						} else {
							PRINOR_TOASTS.delete.error(this, data.error);
						}
					});
				}
			});
		},
		get: function() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.get('theme/all').then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === true) {
							resolve(data.data);
						} else {
							PRINOR_TOASTS.general.error(this, data.data.error);
						}
					}
				});
			});
		},
		duplicate: function(id, value) {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.post('theme/copy', { id: id, name: value }).then(data => {
					if (ApiService.checkSuccess(data)) {
						resolve(data.data);
					}
				});
			});
		},
		delete: function(id) {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.delete('theme/' + id).then(data => {
					if (ApiService.checkSuccess(data)) {
						resolve(data.data);
					}
					else {
						if (data.data != null && data.data.data != null) {
							let text = this.$t('VIEWS.Survey.Theme.deleteSurveysText') + "";
							data.data.data.forEach(survey => {
								text += "<br/>" + survey.name;
							});

							Swal.fire({
								title: this.$t('VIEWS.Survey.Theme.deleteError'),
								html: text,
								icon: 'error',
								confirmButtonText: this.$t('GENERAL.General.okay'),
								customClass: {
									confirmButton: 'btn btn-danger',
									cancelButton: 'btn btn-secondary',
								},
								heightAuto: false,
								allowOutsideClick: false,
								allowEscapeKey: false,
								allowEnterKey: false,
							});
						}
						else {
							PRINOR_TOASTS.general.error(this, data.data.error);
							resolve(data.data);
						}
					}
				});
			});
		},
		onDelete: function(id, name) {
			Swal.fire({
				title: this.$t('VIEWS.Survey.Theme.delete'),
				text: this.$t('VIEWS.Survey.Theme.deleteText', { name: name }),
				icon: 'question',
				confirmButtonText: this.$t('GENERAL.Forms.delete'),
				showCancelButton: true,
				cancelButtonText: this.$t('GENERAL.Buttons.cancel'),
				customClass: {
					confirmButton: 'btn btn-danger',
					cancelButton: 'btn btn-secondary',
				},
				heightAuto: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			}).then(result => {
				if (result.isConfirmed) {
					this.delete(id).then(data => {
						if (data.success === true) {
							this.onGet();
						} else {
							PRINOR_TOASTS.delete.error(this, data.error);
						}
					});
				}
			});
		},
	},
};
</script>

<style>
.text-valign {
	vertical-align: middle !important;
}
</style>
